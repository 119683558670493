import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import GalleryPageBanner from './GalleryPageBanner';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const VIEW_GALLERIES_URL = '/galleries';

function Galleries() {
  const axiosPrivate = useAxiosPrivate();
  const [galleries, setGalleries] = useState([]);

  const navigate = useNavigate();  
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_GALLERIES_URL).then((response) => {
      isMounted && setGalleries(response.data);
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []);
 
  return (
    <>
      <GalleryPageBanner />
      <section id="blog-page" className="pt-90 pb-120 gray-bg">
        <div className="container">
          <div className="row">
            { galleries.length>0 ?            
              galleries.map((value)=>{
                return (
                  <div key={value.id} className='col-lg-4 mt-30 col-md-6'>
                    <div className='singel-blog' onClick={() => navigate(`/gallery/view/${value.id}`)}>
                      <div className="blog-thum">
                        <img src={'/images/' + value.picture} alt="" />
                      </div>   
                      <div className="blog-cont">
                        <Link to={`/gallery/view/${value.id}`}><h4>{value.name}</h4></Link>
                        <ul>
                          <li><Link to='#date'><i class="fa fa-calendar"></i>{new Date(value.createdAt).toDateString()}</Link></li>
                        </ul>
                        <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: value.description}} />
                      </div>
                    </div>
                  </div>
                );
              })
            :
              <>
                  <div className='contact-from mt-30 mb-300 col-lg-12'>
                    <div className="section-title">
                      <h5>gallery</h5>
                      <h2>Photo Galleries</h2>
                    </div> { /* <!-- section title --> */ }
                    <div className='blog-details pt-45 mb-200'>
                      <p>There are no photos at the moment.<br /><br /></p> <h6><Link to='/'>Return to the Home Page</Link></h6>
                    </div>
                  </div>
              </>
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default Galleries