import { Route, Routes} from 'react-router-dom';
import Profiles from '../Modules/Profiles';
import PageNotFound from './PageNotFound'
import Page from './Page';

function Pages() {  
    return (
        <Routes>
            <Route exact path='/about/*' element={<Page id={'about'}/>} />
            <Route exact path='/welcome/*' element={<Page id={'welcome'} />} />
            <Route exact path='/principal/*' element={<Page id={'principal'} />} />
            <Route exact path='/academic/*' element={<Page id={'academic'} />} />
            <Route exact path='/arts/*' element={<Page id={'arts'} />} />
            <Route exact path='/sport/*' element={<Page id={'sport'} />} />
            <Route exact path='/leadership/*' element={<Page id={'leadership'} />} />
            <Route exact path='/admissions/*' element={<Page id={'admissions'} />} />
            <Route exact path='/visitor/*' element={<Page id={'visitor'} />} />
            <Route exact path='/vacancies/*' element={<Page id={'vacancies'} />} />
            <Route exact path='/support/*' element={<Page id={'support'} />} />
            <Route exact path='/governors/*' element={<Profiles id={10} />} />
            <Route exact path='/management/*' element={<Profiles id={20} />} />
            <Route exact path='/teachers/*' element={<Profiles id={30} />} />       
            <Route exact path='/*' element={<PageNotFound />} />
      </Routes>
    )
}

export default Pages