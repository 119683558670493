/* eslint-disable jsx-a11y/anchor-has-content */
import { Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import GalleryPageBanner from './GalleryPageBanner';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

const DELETE_GALLERY_URL = '/galleries/delete/';
const EDIT_GALLERY_URL = '/galleries/edit/';
const VIEW_GALLERY_URL = '/galleries/view/';
const UPLOAD_FILE_URL = '/upsingle';

function ViewGallery() {
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const axiosPrivate = useAxiosPrivate();
  let {id} = useParams();

  const [editGallery, setEditGallery] = useState(false);
  const [description, setDescription] = useState('');
  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [gallery, setGallery] = useState({});  
  const [images, setImages] = useState([]);
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_GALLERY_URL + id).then((response)=>{
      if(isMounted){
        setGallery(response.data.gallery);
        setName(response.data.gallery.name);
        setDescription(response.data.gallery.description);
        setImages(response.data.images);  
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]); 
  
  const deleteGallery = async(id) => {
      try {
        axiosPrivate.delete(DELETE_GALLERY_URL + id).then(()=>{
          alert('Gallery Deleted');
          navigate('/gallery');
        });               
      } catch (error) {       
      }
  }
  const addGalleryPics = async(id) => {
    navigate(`/gallery/addpics/${id}`);
  } 
  const updateGallery = async(e) => {
      e.preventDefault();
      const updatedGallery = {name, description, userId: authDecode.user.id, id: gallery.id};
      const galleryId = gallery.id;
  
      if(file){
        const formData = new FormData();
        const filename = Date.now() + '--' + file.name;
            
        formData.append("name", filename);
        formData.append("file", file);
        updatedGallery.picture = filename;
      
        try{
          await axiosPrivate.post(UPLOAD_FILE_URL, formData);
        }catch(err){
          console.log(err);
        }
      }
        
      try {
        axiosPrivate.put(EDIT_GALLERY_URL + galleryId, updatedGallery).then(()=>{
          alert('Gallery Edited');
          setEditGallery(false);
          navigate(`/gallery/view/${galleryId}`);
        });   
      }catch (error) {
          console.log(error);  
      }
  }
  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }
  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( images.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }
  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === images.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }

  return (
    <>
      <GalleryPageBanner PT={gallery.name} />
      <section className='pt-90 pb-120 gray-bg'>
        <div className="container">

              <div className="blog-details mt-30">
                <div className='row mb-10'>
                  <div className='col-lg-4'>
                    { file ? (
                      <img className="pb-10" src={URL.createObjectURL(file)} alt="" style={{objectFit: 'cover'}} />
                    ): (
                      gallery?.picture && <img src={'/images/' + gallery.picture} alt="" className="pb-10" style={{objectFit: 'cover'}}  />
                    )}
                  </div>
                  <div className='col-lg-8 blog-details'>
                    { editGallery ? (
                      <div className='main-form'> 
                        <form className="p-2">
                          <div className="form-group"> 
                              <label htmlFor="fileInput"><h5>Change Image: <i className="fa fa-recycle" /></h5></label> 
                              <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                          </div>
                          <div className="form-group section-title">
                              <br /><h5>Title</h5>
                                <input className="singel-form form-control p-2" required="required" type='text' value={name} onChange={(e)=>setName(e.target.value)} />
                          </div>
                          <div className="form-group section-title">
                            <br /><h5>Description</h5>      
                            <textarea className="singel-form form-control p-2" rows="3" value={description} onChange={(e)=>setDescription(e.target.value)} />
                          </div>
                          <div className="singel-form form-group">
                            <br />
                              <button type="submit" className="main-btn" onClick={updateGallery}>Update</button>
                              &nbsp;&nbsp;&nbsp;  
                              <button className="main-btn" onClick={(e)=>setEditGallery(false)}>Cancel</button>
                          </div>
                        </form>
                      </div> 
                    ):(
                      <div className='cont'>
                        <h4>{gallery.name}</h4>
                        <p className='p-2'>{gallery.description}</p>
                        {authDecode && (authDecode?.user?.id === gallery.UserId || authDecode?.user?.role >=4) ? (
                            <ul>
                              <li><Link to='#image'><i className="fa fa-image" aria-hidden="true" onClick={() => addGalleryPics(gallery.id)}/></Link>Add Images</li>
                              <li><Link to='#edit'><i className="fa fa-edit" aria-hidden="true" onClick={() => setEditGallery(true)}/></Link>Edit Gallery</li>
                              <li><Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteGallery(gallery.id)} /></Link>Delete Gallery</li>
                            </ul>
                        ) : (<></>)}
                      </div>
                    )}
                  </div>
                </div>
                { openModal &&
                  <div className="col-lg-12">
                    <div className='sliderWrap'>
                      <span className='btnClose' onClick={handleCloseModal}>X</span>
                      <span className='btnPrev' onClick={prevSlide}>&#10094;</span>
                      <span className='btnNext' onClick={nextSlide}>&#10095;</span>
                      <div className='fullScreenImage'>
                        <img src={'/images/' + images[slideNumber].picture} alt='' /><br />
                      </div>
                    </div>
                    <div style={{backgroundImage: 'url(images/page-banner-6.jpg)'}}>{images[slideNumber].caption}</div>
                  </div>              
                }
                <div className="row">
                  { images && images.map((slide, index) => {
                    return(
                      <div className='col-3 singel-blog mb-30' key={slide.id}onClick={ () => handleOpenModal(index) }>
                        <img src={'/images/' + slide.picture} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
                      </div>
                    )           
                  })}
                </div>
              </div>

        </div> {/* container */} 
      </section>
    </>
  )
}

export default ViewGallery