import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import ProfilesPageBanner from './ProfilesPageBanner';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';

const VIEW_PROFILES_URL = '/profiles/';

function Profiles({id}) {
    const [profiles, setProfiles] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    
      useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PROFILES_URL + id).then((response) => {
            isMounted && setProfiles(response.data);
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);
    
    let team = '';
    let ptag = '';
    if(id === 10){
        team = 'governors';
        ptag = 'Governors'
    }else if(id === 20){
         team = 'management';
        ptag = 'Management'
    }else if(id === 30){
        team = 'teachers';
        ptag = 'Teachers'
    }

    return (
        <>
            <ProfilesPageBanner tag={ptag} team={team}/>           
            <section className='pt-90 pb-120 gray-bg'>
                <div className="container">
                    <div className="row">
                        { profiles.map((value)=>{
                            return (
                                <div className="col-lg-3 col-sm-6" key={value.id}>
                                    <div className="singel-teachers mt-30 text-center">
                                        <div className="image">
                                            <img src={'/images/' + value.pictures} alt="Profile"/>
                                        </div>
                                        <div className="cont">
                                            <Link to={`profiles/view/${value.id}`}><h6>{value.name}</h6></Link>
                                            <span>{value.position}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>{/* row with profile cards*/}           
                </div>
            </section>
        </>
    )
}

export default Profiles