import { Link, useNavigate} from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import useAuth from '../../Hooks/useAuth';
import {useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode';

//Editor
import EditorToolbar, { modules, formats } from "../../Components/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../css/TextEditor.css";

const VIEW_PAGE_URL = '/pages/view/about';
const EDIT_PAGE_URL = '/pages/edit/about';

function PagesIntro() {
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [page, setPage] = useState([]);  
  const [title, setTitle] = useState('');
  const [pageIntro, setPageIntro] = useState('');
  const [updateMode, setUpdateMode] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  //let {id} = useParams();

  useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_PAGE_URL).then((response)=>{
          if(isMounted){
              setPage(response.data);
              setTitle(response.data.title);
              setPageIntro(response.data.pageIntro);
          }
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Response Data Error' + error.response.data);
          console.log('Response Status Error' + error.response.status);
          console.log('Response Headers Error' + error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log('Request Error: ' + error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
  }, []);

  const onPageIntro = (value) => {
      setPageIntro(value);
  }

  const updatePage = async(e) => {
      e.preventDefault();
      const updatedPage = {title, pageIntro, id: page.id};
      const pageId = page.id;
      
      try {
          axiosPrivate.put(EDIT_PAGE_URL + pageId, updatedPage).then(()=>{
              alert('About Page Edited');
              setUpdateMode (false);
              navigate(`/`);
          });   
      }catch (error) {
          console.log(error);  
      }
  }

  return (
    <div className="col-lg-5">
    {updateMode ? 
      <>
        <form className="p-2">
            <div className="section-title form-group mt-50">
              <h5>{page.tag}</h5>                  
              <br /><h5>Title</h5> <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
            </div> { /*<!-- section title --> */}
            <div className="section-title form-group">
                <br /><h5>Detail</h5>
                <EditorToolbar toolbarId={'t1'} />
                <ReactQuill theme="snow" rows="3" modules={modules('t1')} formats={formats} value={pageIntro} onChange={onPageIntro} />
            </div>
          <br />
          <button type="submit" className="main-btn" onClick={updatePage}>Update</button>
          &nbsp;&nbsp;&nbsp;  
          <Link className="main-btn" onClick={(e)=>setUpdateMode(false)} to={`/`}>Cancel</Link> 
        </form> 
      </> 
    : 
      <>
        { page && <>
            <div className="section-title mt-50">
            <h5>{page.tag}</h5>
            {(authDecode && authDecode.user.role >= 4) && 
                <span className='about-cont'>
                    <Link to='#edit'><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/> </Link>
                    &nbsp;Edit
                </span>
              }
            <h2>{page.title}</h2>
            </div>
            <div className="about-cont">
              <p className="about-cont pt-20" dangerouslySetInnerHTML={{ __html: page.pageIntro}} style={{whiteSpace: "pre-line"}} />
              <Link to="/welcome" className="main-btn mt-55">Learn More</Link>
            </div>
          </>}
      </>
    }
    </div>

  )
}

export default PagesIntro