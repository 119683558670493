import { Link, useParams, useNavigate} from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import RightSideBar from "../../Components/RightSideBar";
import GalleryPageBanner from "./GalleryPageBanner";
import { useState, useEffect } from 'react';

const VIEW_GALLERY_URL = '/galleries/view/';
const UPLOAD_FILE_URL ='/upsingle';
const ADD_PICTURES_URL ='/galleries/addpics/';

function AddPictures() {
  const axiosPrivate = useAxiosPrivate();
  const [gallery, setGallery] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();
  let {id} = useParams();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_GALLERY_URL + id).then((response)=>{ 
      isMounted && setGallery(response.data.gallery);
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;    
    const selectedFilesArray = Array.from(selectedFiles);
  
    const imagesArray = selectedFilesArray.map((file) => {
      return ( {"image": URL.createObjectURL(file), "file": file, "caption": "" } )
    });
    
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    
    // FOR BUG IN CHROME
    e.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));   
    URL.revokeObjectURL(image.image);
  }

  const handleChangeInput = (id, e) => {
    const newCaption =  e.target.value
    selectedImages.map((image) => {
      if(image === id){
        image.caption = newCaption;
      }
      return image.caption;
    })
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    const pictures = [];
    if(selectedImages){           
      for(let i=0; i<selectedImages.length; i++) {
        const formData = new FormData();
        const filename = Date.now()+ ' - ' + selectedImages[i].file.name;       
        formData.append("name", filename);
        formData.append("file", selectedImages[i].file);
        try{
          await axiosPrivate.post(UPLOAD_FILE_URL, formData);   
        }catch (error) {
          console.log(error);  
        }        
        const addedCaption = selectedImages[i].caption;
        const newPic = {picture: filename, caption: addedCaption, galleryId: id};
        pictures.push(newPic);
      }
    }

    try {
      await axiosPrivate.post(ADD_PICTURES_URL + id, pictures).then((response)=>{
        navigate(`/gallery/view/${id}`);
      }).catch(function(error){
        navigate(`/gallery/view/${id}`);
      });
    }catch (error) {
      console.log(error);
      navigate(`/gallery/view/${id}`); 
    }  
  }

  return (<>
    <GalleryPageBanner PT='Pictures' GN={gallery.name} Gid={gallery.id} />
    <div className="pt-90 pb-120 gray-bg">
      <div className="container">
        <div className="row">
          <div className='col-lg-8'>
            <div className="main-form pt-45">
              <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    {selectedImages.length === 0 &&
                        <label htmlFor="fileInput">
                          <h5><i className="fa fa-plus"></i> Select Pictures to Add</h5>
                          <input id="fileInput" type="file" name="files" style={{display:'none'}} onChange={(e) => onSelectFile(e)} multiple accept="image/png, image/jpeg, image/jpg" />
                        </label>
                    }
                  </div>
                  <div className="form-group">
                    {selectedImages && selectedImages.map((image) => { 
                        return (
                        <div className='form-row' key={image.image}>
                            <div className='col-md-3'>
                              <img src={image.image} alt="upload" /><br />
                              <i className="fa fa-trash" aria-hidden="true" onClick={() => deleteHandler(image)}/>
                            </div>
                            <div className='col-md-9'>
                              <input placeholder='Insert Caption' className='form-control p-4' onChange={(e) => handleChangeInput(image, e)} />
                            </div>
                        </div>
                        );
                    })}
                    <button className="main-btn" disabled={!selectedImages} type="submit">Submit</button>
                    &nbsp;&nbsp;&nbsp;  
                    <Link className="main-btn" to={`/gallery/view/${id}`}>Cancel</Link>  
                  </div>
              </form>
            </div>
          </div>
          <RightSideBar />
        </div>
      </div>
    </div>
    </>
  );
}

export default AddPictures