import RequireAuth from '../Components/RequireAuth';
import { Routes, Route} from 'react-router-dom';
import ViewProfiles from './Profiles/Profiles';
import AddProfile from './Profiles/AddProfile';
import ViewProfile from './Profiles/Profile';

function Profiles({id}) {

  return (
      <Routes>
        <Route exact path='/' element={<ViewProfiles id={id} />} />
        <Route exact path='/view/:id' element={<ViewProfile />} />
        <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
          <Route exact path='/add' element={<AddProfile />} />
        </Route>
      </Routes>
  )
}

export default Profiles