import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import { useState } from "react";
import EventsPageBanner from './EventsPageBanner';

const UPLOAD_FILE_URL = '/upsingle';
const ADD_EVENT_URL = '/events/add';

function AddEvent() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [location, setLocation] = useState('');
  const [file, setFile] = useState(null);
  let navigate = useNavigate();
  
  const eventSubmit = async (e) => {
    e.preventDefault();

    const date = eventDate;
    const newEvent = {title, date, location, description, author: authDecode.user.id, };
    
    if(file){
      const formData = new FormData();
      const filename = Date.now() + file.name;
      
      formData.append("name", filename);
      formData.append("file", file);
      newEvent.picture = filename;

      try{
        await axiosPrivate.post(UPLOAD_FILE_URL, formData);
      }catch(err){
        console.log(err);
      }
    }
    
    try {
      await axiosPrivate.post(ADD_EVENT_URL, newEvent).then((response)=>{
        console.log(response);
        navigate('/events');
      });   
    }catch (error) {
      console.log(error);  
    }
  };

  return (
    <>
      <EventsPageBanner PT={'Add Event'} />
      <section id="event-singel" className="pt-120 pb-120 gray-bg">
        <div className="container">
          <div className='events-area'>
          <div className='main-form'>
              <form data-toggle="validator">
                  <div className="form-group section-title">
                      <br/><h5>Event Name</h5>
                      <input type='text' value={title} className="form-control p-2" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />         
                      <div className="help-block with-errors"></div>
                  </div> {/* Event Title */}
                  <div className="row">
                      <div className="col-lg-6">
                          <div class="events-left">
                              { file &&  <img src={URL.createObjectURL(file)} alt="" /> }
                          </div>{/* Event Image */}
                          <div className='form-group'>
                              <label htmlFor='fileInput'><br />
                                { file ? <h5><i className="fa fa-recycle" /> Change Image</h5> : <h5><i className="fa fa-image" /> Add Image</h5>}
                              </label>
                              <input type='file' id='fileInput' style={{display: 'none'}} onChange={(e)=>setFile(e.target.files[0])} />
                          </div> {/* Change Image */}
                      </div>{/* Main */}
                      <div className="events-left col-lg-6">
                          <div className="events-right pt-10">
                              <div className="form-group section-title">
                                  <br/><h5>Date</h5>
                                  <input type='date' className="p-2 form-control" autoFocus={true} onChange={(e)=>setEventDate(e.target.value)} />
                                  <div className="help-block with-errors"></div>
                              </div> {/* Event Date*/}               
                              <div className="form-group section-title">
                                  <br /><h5>Location</h5>
                                  <input placeholder='Event Location' type='text' className="form-control p-2" autoFocus={true} onChange={(e)=>setLocation(e.target.value)} />
                                  <div className="help-block with-errors"></div>
                              </div>{/* Location */}
                              <div className="form-group section-title">
                                <br /><h5>Description</h5>
                                <textarea placeholder='Event description' type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
                                <div className="help-block with-errors"></div>
                            </div>
                          </div> { /* <!-- events right --> */}
                      </div>{/* Rightside */}
                  </div>
                  <br />
                  <button className="main-btn" disabled={!title || !eventDate || !location || !description} type="submit" onClick={(e)=>eventSubmit(e)}>Submit</button>
                  &nbsp;&nbsp;&nbsp;  
                  <Link className="main-btn" to='/events'>Cancel</Link>
              </form>
          </div>
          </div>
          {/* {file && (
            <img className="img-fluid" src={URL.createObjectURL(file)} alt="" />
          )}
          <div className="main-form pt-45">
            <form onSubmit={eventSubmit}>
              <div className='form-group'>
                  <label htmlFor='fileInput'><i className='writeIcon fas fa-plus'></i> Add Event Image</label>
                  <input type='file' id='fileInput' style={{display: 'none'}} onChange={(e)=>setFile(e.target.files[0])} />
              </div>
              <div class="form-group">
                <input type='text' placeholder='Event Name' className="form-control p-2" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />         
              </div>
              <div className="form-group">
                <input type='date' className="p-2 form-control" autoFocus={true} onChange={(e)=>setEventDate(e.target.value)} />
              </div>                
              <div class="form-group">
                <input type='text' placeholder='Location' className="form-control p-2" autoFocus={true} onChange={(e)=>setLocation(e.target.value)} />
              </div>
              <div class="form-group">
                <textarea placeholder='Event Details ...' type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
              </div>
              <button className="main-btn" disabled={!file || !title || !eventDate || !location || !description} type="submit">Submit</button>
              &nbsp;&nbsp;&nbsp;  
              <Link className="main-btn" to='/events'>Cancel</Link>  
            </form>
          </div> */}
        </div>
      </section>    
    </>
  )
}

export default AddEvent
