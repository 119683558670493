import {Link} from 'react-router-dom';
import EventsList from './EventsList';
import PagesIntro from './PagesIntro';

function About() {
  return (
    <>
      <section id="about-part" className="pt-65">
        <div className="container">
          <div className="row">
            <PagesIntro />
            <EventsList />
          </div> 
        </div>
        <div className="about-bg">
          <img src="images/about/IMG_7827.jpg" alt="About" />
        </div>
      </section>

      <section id="apply-aprt" className="pb-120">
        <div className="container">
          <div className="apply">
            <div className="row no-gutters">
              <div className="col-lg-6">
                <div className="apply-cont apply-color-1">
                  <h3>Apply for fall {new Date().getFullYear() + 1} intake</h3>
                  <p>Gravida nibh vel velit auctor aliquetn sollicitudirem sem quibibendum auci elit cons equat ipsutis sem nibh id elituis sed odio sit amet nibh vulputate cursus equat ipsutis.</p>
                  <Link to="/apply" className="main-btn">Apply Now</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="apply-cont apply-color-2">
                  <h3>Apply for scholarship</h3>
                  <p>Gravida nibh vel velit auctor aliquetn sollicitudirem sem quibibendum auci elit cons equat ipsutis sem nibh id elituis sed odio sit amet nibh vulputate cursus equat ipsutis.</p>
                  <Link to="/" className="main-btn">Apply Now</Link>
                </div> 
              </div> 
            </div>
          </div> 
        </div> 
      </section>      
    </>
  )
}

export default About