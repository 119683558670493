import {Link} from 'react-router-dom';

function RightSideBar() {
 
  return (
    <div className="col-lg-4">
      <div className="saidbar">
          <div className="row">
              <div className="col-lg-12 col-md-6">
                  <div className="saidbar-search mt-30">
                      <form action="#">
                          <input type="text" placeholder="Search" />
                          <button type="button"><i className="fa fa-search"></i></button>
                      </form>
                  </div> { /* <!-- saidbar search --> */ }
              </div> { /* <!-- categories --> */ }
              <div className="col-lg-12 col-md-6">
                  <div className="saidbar-post mt-30">
                      <h4>Trending News</h4>
                      <ul>
                          <li>
                            <Link to="/">
                                <div className="singel-post">
                                    <div className="thum">
                                        <img src="images/blog/blog-post/bp-1.jpg" alt="Blog" />
                                    </div>
                                    <div className="cont">
                                        <h6>Introduction to languages</h6>
                                        <span>20 Dec 2018</span>
                                    </div>
                                </div> { /* <!-- singel post --> */ }
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                                <div className="singel-post">
                                    <div className="thum">
                                        <img src="images/blog/blog-post/bp-2.jpg" alt="Blog" />
                                    </div>
                                    <div className="cont">
                                        <h6>How to build a game with java</h6>
                                        <span>10 Dec 2018</span>
                                    </div>
                                </div> { /* <!-- singel post --> */ }
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                                <div className="singel-post">
                                    <div className="thum">
                                        <img src="images/blog/blog-post/bp-1.jpg" alt="Blog" />
                                    </div>
                                    <div className="cont">
                                        <h6>Basic accounting from primary</h6>
                                        <span>07 Dec 2018</span>
                                    </div>
                                </div> { /* <!-- singel post --> */ }
                            </Link>
                          </li>
                      </ul>
                  </div> { /* <!-- Sidebar Popular posts --> */ }
                  <div className="saidbar-post mt-30">
                      <h4>Latest Blog Posts</h4>
                      <ul>
                          <li>
                            <Link to="/">
                                <div className="singel-post">
                                    <div className="thum">
                                        <img src="images/blog/blog-post/bp-1.jpg" alt="Blog" />
                                    </div>
                                    <div className="cont">
                                        <h6>Introduction to languages</h6>
                                        <span>20 Dec 2018</span>
                                    </div>
                                </div> { /* <!-- singel post --> */ }
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                                <div className="singel-post">
                                    <div className="thum">
                                        <img src="images/blog/blog-post/bp-2.jpg" alt="Blog" />
                                    </div>
                                    <div className="cont">
                                        <h6>How to build a game with java</h6>
                                        <span>10 Dec 2018</span>
                                    </div>
                                </div> { /* <!-- singel post --> */ }
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                                <div className="singel-post">
                                    <div className="thum">
                                        <img src="images/blog/blog-post/bp-1.jpg" alt="Blog" />
                                    </div>
                                    <div className="cont">
                                        <h6>Basic accounting from primary</h6>
                                        <span>07 Dec 2018</span>
                                    </div>
                                </div> { /* <!-- singel post --> */ }
                            </Link>
                          </li>
                      </ul>
                  </div> { /* <!-- Sidebar Latest posts --> */ }
              </div>
          </div> { /* <!-- row --> */ }
      </div> { /* <!-- saidbar --> */ }
    </div>
  )
}

export default RightSideBar