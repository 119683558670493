import {Routes, Route} from 'react-router-dom';
import ViewUser from './User/ViewUser';
import ViewPosts from './User/ViewPosts';
import ViewAlum from './User/ViewAlum';
import RightSideBar from '../Components/RightSideBar';

function User() {
  return (
    <div className="container-fluid pt-5 mb-3">
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/posts/:id' element={<ViewPosts />} />
            <Route exact path='/alum/:id' element={<ViewAlum />} />
            <Route exact path='/view/:id' element={<ViewUser />} />
          </Routes>
          <RightSideBar />
        </div>
      </div>
    </div>
  )
}

export default User