import LeftSideBar from '../Components/LeftSideBar';
import {Routes, Route} from 'react-router-dom';
import UserDetails from './Admin/UserDetails';
import Dashboard from './Admin/Dashboard';
import Users from './Admin/Users';

function Admin() {
  return (
    <div className="container-fluid pt-5 mb-3">
      <div className="container">
        <div className='row'>
          <LeftSideBar />
          <Routes>
            <Route exact path='/users/:id' element={<UserDetails />} />
            <Route exact path='/users' element={<Users />} />
            <Route exact path='/' element={<Dashboard />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Admin