import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';

const CONTACT_URL = '/mail';

function Contact() {
    const axiosPrivate = useAxiosPrivate();
    const [senderName, setSenderName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [senderPhone, setSenderPhone] = useState('');
    const [inquiry, setInquiry] = useState('');
    let navigate = useNavigate();

    const sendEmail = async (e) => {
        e.preventDefault();
        const pageName = 'Inquiry'
        const contactMsg = { pageName, senderName, senderEmail, senderPhone, inquiry, };

        try {
            await axiosPrivate.post(CONTACT_URL, contactMsg).then((response)=>{
                alert('Message Sent');
                navigate('/');
            });   
        }catch (error) {
            console.log(error);  
        }

    }

    return (
        <>
            <section id="page-banner" className="pt-105 pb-80 bg_cover" data-overlay="8" style={{backgroundImage: 'url(images/page-banner-6.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-banner-cont">
                                <h2>Contact Us</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                    </ol>
                                </nav>
                            </div>  { /* <!-- page banner cont --> */}
                        </div>
                    </div> { /* <!-- row --> */}
                </div> { /* <!-- container --> */}
            </section>
            <section id="contact-page" className="pt-90 pb-120 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="contact-from mt-30">
                                <div className="section-title">
                                    <h5>Contact Us</h5>
                                    <h2>Keep in touch</h2>
                                </div> { /* <!-- section title --> */ }
                                <div className="main-form pt-45">
                                    <form id="contact-form" onSubmit={sendEmail} data-toggle="validator">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="singel-form form-group">
                                                    <input name="senderName" type="text" placeholder="Your name" data-error="Name is required." required="required" onChange={(e)=>setSenderName(e.target.value)}/>
                                                    <div className="help-block with-errors"></div>
                                                </div> { /* <!-- singel form --> */ }
                                            </div>

                                            <div className="col-md-6">
                                                <div className="singel-form form-group">
                                                    <input name="senderEmail" type="email" placeholder="Contact Email" data-error="Valid email is required." required="required" onChange={(e)=>setSenderEmail(e.target.value)} />
                                                    <div className="help-block with-errors"></div>
                                                </div> { /* <!-- singel form --> */ }
                                            </div>
                                            <div className="col-md-6">
                                                <div className="singel-form form-group">
                                                    <input name="senderPhone" type="text" placeholder="Phone Number" data-error="Phone is required." required="required" onChange={(e)=>setSenderPhone(e.target.value)} />
                                                    <div className="help-block with-errors"></div>
                                                </div> { /* <!-- singel form --> */ }
                                            </div>
                                            <div className="col-md-12">
                                                <div className="singel-form form-group">
                                                    <textarea name="inquiry" placeholder="Message" className="form-control p-2" rows="10" data-error="Please,leave us a message." required="required" onChange={(e)=>setInquiry(e.target.value)}></textarea>
                                                    <div className="help-block with-errors"></div>
                                                </div> { /* <!-- singel form --> */ }
                                            </div>
                                            <p className="form-message"></p>
                                            <div className="col-md-12">
                                                <div className="singel-form">
                                                    <button type="submit" className="main-btn">Send</button>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <Link className="main-btn" to='/'>Cancel</Link> 
                                                </div> { /* <!-- singel form --> */ }
                                            </div> 
                                        </div> { /* <!-- row --> */ }
                                    </form>
                                </div> { /* <!-- main form --> */ }
                            </div> { /* <!--  contact from --> */ }
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-address mt-30">
                                <ul>
                                    <li>
                                        <div className="singel-address">
                                            <div className="icon">
                                                <i className="fa fa-home"></i>
                                            </div>
                                            <div className="cont">
                                                <p>Plot 6A Channel, Yusuf Lule Road, Kampala</p>
                                            </div>
                                        </div> { /* <!-- singel address --> */ }
                                    </li>
                                    <li>
                                        <div className="singel-address">
                                            <div className="icon">
                                                <i className="fa fa-phone"></i>
                                            </div>
                                            <div className="cont">
                                                <p>+256 392 700 370</p>
                                            </div>
                                        </div> { /* <!-- singel address --> */ }
                                    </li>
                                    <li>
                                        <div className="singel-address">
                                            <div className="icon">
                                                <i className="fa fa-envelope-o"></i>
                                            </div>
                                            <div className="cont">
                                                <p>info@kja.ug</p>
                                            </div>
                                        </div> { /* <!-- singel address --> */ }
                                    </li>
                                </ul>
                            </div> { /* <!-- contact address --> */ }
                            <div className="map mt-30">
                                <div id="contact-map"></div>
                            </div> { /* <!-- map --> */ }
                        </div>
                    </div> { /* <!-- row --> */ }
                </div> { /* <!-- container --> */ }
            </section>
        </>
    )
}

export default Contact