import RequireAuth from '../Components/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import AddStory from './News/AddStory';
import Stories from './News/Stories';
import Story from './News/Story';

function News() {
  return (
    <Routes>
      <Route exact path='/' element={<Stories />} />
      <Route exact path='/view/:id' element={<Story />} />
      <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
        <Route exact path='/add' element={<AddStory />} />
      </Route>
    </Routes>
  )
}

export default News