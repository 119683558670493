import axios from 'axios';

let BASE_URL = 'http://localhost:3007';

if(process.env.NODE_ENV === 'production'){ BASE_URL = 'https://ngongaha.com/api'; }

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});