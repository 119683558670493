import RightSideBar from "../../Components/RightSideBar";
import NewStoryForm from "./NewStoryForm";
import NewsPageBanner from "./NewsPageBanner";

function AddStory() {
  return (
    <>
      <NewsPageBanner PT='Post News Story' />
      <div className="pt-90 pb-120 gray-bg">
        <div className="container">
          <div className="row">
            <NewStoryForm />
            <RightSideBar/>
          </div>
        </div>
      </div>
    </>

  );
}

export default AddStory