import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import RightSideBar from "../../Components/RightSideBar";
import { Link, useNavigate } from "react-router-dom";
import GalleryPageBanner from "./GalleryPageBanner";
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import { useState } from "react";

const UPLOAD_FILE_URL = '/upsingle';
const ADD_GALLERY_URL = '/galleries/add';

function AddGallery() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  let navigate = useNavigate();
    
  const handleGallerySubmit = async (e) => {
    e.preventDefault();
    const newGallery = {name, description, archive: 0, userId: authDecode.user.id};
      
    if(file){
      const formData = new FormData();
      const filename = Date.now() + '--gallery--' + file.name;
        
      formData.append("name", filename);
      formData.append("file", file);
      newGallery.picture = filename;
    
      try{
        await axiosPrivate.post(UPLOAD_FILE_URL, formData);
      }catch(err){
        console.log(err);
      }
    }
    try {
      await axiosPrivate.post(ADD_GALLERY_URL, newGallery).then((response)=>{
        navigate('/gallery');
      });   
    }catch (error) {
      console.log(error);  
    }
  };

  return (<>
    <GalleryPageBanner PT='Add New Gallery' />
    <div className="pt-90 pb-120 gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="thumb">
              {file && (
                <img src={URL.createObjectURL(file)} alt="" />
              )}
            </div>
            <div className="main-form pt-45">
              <form action="" onSubmit={handleGallerySubmit}>
                <div className="singel-form form-group">
                  <label htmlFor="fileInput" className="p-2">
                    { file ? <h5><i className="fa fa-recycle"></i> Change Image</h5> : <h5><i className="fa fa-image" /> Add Image</h5> }</label>
                  <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                </div>
                <div className="section-title form-group">
                  <br /><h5>Name</h5>
                  <input type="text" placeholder="Gallery Name" className="form-control p-4" autoFocus={true} onChange={(e)=>setName(e.target.value)} />
                </div>
                <div className="section-title form-group">
                  <br /><h5>Description</h5>
                  <textarea placeholder="Gallery description ..." type="text" className="form-control p-4" rows="4" onChange={(e)=>setDescription(e.target.value)} />
                </div>
                <br />
                <button className="main-btn" disabled={!file || !name || !description} type="submit">Submit</button>
                &nbsp;&nbsp;&nbsp;  
                <Link className="main-btn" to='/gallery'>Cancel</Link>  
              </form>
            </div>
          </div>
          <RightSideBar />
        </div>
      </div>
    </div>
    </>)
}

export default AddGallery
