/* eslint-disable jsx-a11y/anchor-has-content */
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const VIEW_EVENTS_URL = '/events/home';

function EventsList() {
  const axiosPrivate = useAxiosPrivate();
  const [eventList, setEventList] = useState([]);  
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_EVENTS_URL).then((response)=>{
      isMounted && setEventList(response.data);
    }).catch((error) => {
      console.log('Error', error.message);
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-6 offset-lg-1">
      <div className="about-event mt-50">
      <div className="event-title">
        <h3>Upcoming events</h3>
      </div> 
      <ul>
        { eventList.map((calEvent) => {
          return (
            <li key={calEvent.id} >
              <div className="singel-event">
                <span><i className="fa fa-calendar" /> {new Date(calEvent.date).toDateString()}</span>
                <Link to={`/events/view/${calEvent.id}`}><h4>{calEvent.title}</h4></Link>
                <span><i className="fa fa-map-marker" /> {calEvent.location}</span>
              </div>
            </li>
          )
        })}
        </ul> 
      </div>
    </div>
  )
}

export default EventsList