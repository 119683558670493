import { Link } from 'react-router-dom';
function Pagination() {
  return (
    <div className="col-lg-12">
        <nav className="courses-pagination mt-50">
            <ul className="pagination justify-content-center">
                <li className="page-item">
                    <Link to="/" aria-label="Previous">
                        <i className="fa fa-angle-left"></i>
                    </Link>
                </li>
                <li className="page-item"><Link className="active" to="/">1</Link></li>
                <li className="page-item"><Link to="/">2</Link></li>
                <li className="page-item"><Link to="/">3</Link></li>
                <li className="page-item">
                    <Link to="/" aria-label="Next">
                        <i className="fa fa-angle-right"></i>
                    </Link>
                </li>
            </ul>
        </nav>  { /*<!-- courses pagination --> */}
    </div>
)}

export default Pagination