import Newshighlights from "./Home/Newshighlights";
import Facilities from "./Home/Facilities";
import Teachers from "./Home/Teachers";
import About from "./Home/About";
import Hero from "./Home/Hero";

function Home() {  
    return (
      <div className=" gray-bg"> 
        <div className="search-box">
          <div className="serach-form">
            <div className="closebtn">
              <span />
              <span />
            </div>
            <form action="#">
              <input type="text" placeholder="Search by keyword" />
              <button><i className="fa fa-search" /></button>
            </form>
          </div> 
        </div>
        <Hero />
        <About />
        <Facilities />
        <Teachers />
        <Newshighlights />
      </div>
    )
}

export default Home

