import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import {useState} from "react";

//Editor
import EditorToolbar, { modules, formats } from "../../Components/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../css/TextEditor.css";


const UPLOAD_FILE_URL = '/upsingle';
const ADD_ARTICLE_URL = '/articles/add';

function NewArticleForm() {
  const axiosPrivate = useAxiosPrivate();  
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [file, setFile] = useState(null);

  let navigate = useNavigate();

  const onDetail = (value) => {
    setDetail(value);
  } 
  
  const storySubmit = async (e) => {
    e.preventDefault();
    const newStory = { title, detail, userId: authDecode.user.id, };

    if(file){
      const formData = new FormData();
      const filename = Date.now() + file.name;
      
      formData.append("name", filename);
      formData.append("file", file);
      newStory.picture = filename;

      try{
        await axiosPrivate.post(UPLOAD_FILE_URL, formData);
      }catch(err){
        console.log(err);
      }
    }
  
    try {
      await axiosPrivate.post(ADD_ARTICLE_URL, newStory).then((response)=>{
        navigate('/articles');
      });   
    }catch (error) {
      console.log(error);  
    }
  };

  return (

      <section className="col-lg-8">
          <div className="blog-details mt-30">
            <div class="thum">
              {file && (
                <img src={URL.createObjectURL(file)} alt="" />
              )}
            </div>
            <div className='main-form pt-45'>
              <form onSubmit={storySubmit}>
                <div className="singel-form form-group">
                  <label htmlFor="fileInput">
                    {file ? <h5><i className="fa fa-recycle" /> Change Image</h5> : <h5><i className="fa fa-image" /> Add Image</h5>}
                  </label>
                  <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                </div>
                <div className="section-title form-group">
                  <br /><h5>Title</h5>
                  <input type="text" placeholder="Blog Post Title" className="form-control p-4" required="required" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />
                </div>
                <div className="section-title form-group">
                  <br /><h5>Post Content</h5>
                  <EditorToolbar toolbarId={'t1'} />
                  <ReactQuill theme="snow" rows="10" modules={modules('t1')} formats={formats} value={detail} onChange={onDetail} placeholder={"Tell your story ..."} />
                </div>
                <br/>
                <button className="main-btn" disabled={!file || !title || !detail} type="submit">Submit</button>
                &nbsp;&nbsp;&nbsp;  
                <Link className="main-btn" to='/blog'>Cancel</Link>  
              </form>
            </div>
          </div>
      </section>
  )
}

export default NewArticleForm