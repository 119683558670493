import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import {Link} from 'react-router-dom';

function ProfilesPageBanner({tag, team, PT}) {
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

    return (
        <section id="page-banner" className="pt-105 pb-80 bg_cover" data-overlay="8" style={{backgroundImage: 'url(images/page-banner-5.jpg)'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-banner-cont">
                            <h2>{tag}</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link href="/">Home</Link></li>
                                    {PT ? 
                                        <>
                                            {tag && <li className="breadcrumb-item active"><Link href={`/${team}`}>{tag}</Link></li>}
                                            <li className="breadcrumb-item active" aria-current="page">{PT}</li>
                                        </>
                                    : 
                                        <li className="breadcrumb-item active" aria-current="page">{tag}</li>
                                    }
                                </ol>
                                <br />
                                    {(authDecode && authDecode?.user?.role >=3) && 
                                        <ol className="breadcrumb"><li className="breadcrumb-item active"><Link href="/profiles/add"><i className="fa fa-user"></i></Link>Add Profile</li></ol>
                                    }   
                            </nav>
                        </div>  { /* <!-- page banner cont --> */}
                    </div>
                </div> { /* <!-- row --> */}
            </div> { /* <!-- container --> */}
        </section>
    )
}

export default ProfilesPageBanner