import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import { Link} from 'react-router-dom';

const VIEW_NEWS_URL = '/news/home';

function Newshighlights() {
  const axiosPrivate = useAxiosPrivate();
  const [topStory, setTopStory] = useState({});
  const [homeStories, setHomeStories] = useState([]);

  useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_NEWS_URL).then((response) => {
          if(isMounted){
              setTopStory(response.data.topStory);
              setHomeStories(response.data.homeStories);
          } 
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Response Data Error' + error.response.data);
          console.log('Response Status Error' + error.response.status);
          console.log('Response Headers Error' + error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log('Request Error: ' + error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
  }, []);

  return (
    topStory &&
    <section id="news-part" className="pt-115 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title pb-50">
                <h5>Latest News</h5>
                <h2>From the news</h2>
              </div> 
            </div>
          </div> 
          <div className="row">
            { topStory && 
              <div className="col-lg-6">
                <div className="singel-news mt-30">
                  <div className="news-thum pb-25">
                    <img src={'images/' + topStory.picture} alt="News" />
                  </div>
                  <div className="news-cont">
                    <ul>
                      <li><Link to="/"><i className="fa fa-calendar" />{new Date(topStory.createdAt).toDateString()} </Link></li>
                      <li><Link to="/"> <span>By</span> {topStory?.user?.name}</Link></li>
                    </ul>
                    <Link to={`/news/view/${topStory.id}`}><h3>{topStory.title}</h3></Link>
                    <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: topStory.detail}} />
                  </div>
                </div> 
              </div>
            }
            {homeStories && 
              <div className="col-lg-6">
                { homeStories.map((value)=>{
                  return (
                    <div key={value.id} className="singel-news news-list">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="news-thum mt-30">
                          <img src={'images/' + value.picture} alt="News" />
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="news-cont mt-30">
                          <ul>
                            <li><Link to="/"><i className="fa fa-calendar" />{new Date(value.createdAt).toDateString()} </Link></li>
                            <li><Link to="/"> <span>By</span> {value.user.name}</Link></li>
                          </ul>
                          <Link to={`/news/view/${value.id}`}><h3>{value.title}</h3></Link>
                        </div>
                      </div>
                    </div> 
                  </div>
                  )
                
                })}
              </div>
            }
          </div>
        </div>
    </section>
  )
}

export default Newshighlights