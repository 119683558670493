import { AuthProvider } from './Assets/AuthProvider';
import { Route, Routes } from 'react-router-dom';

//Components
import PersistLogin from './Components/PersistLogin';
import RequireAuth from './Components/RequireAuth';
import Footer from './Components/Footer';
import Layout from './Components/Layout';
import Navbar from './Components/Navbar';

// Modules
import Articles from './Modules/Articles';
import Profiles from './Modules/Profiles';
import Gallery from './Modules/Gallery';
import Events from './Modules/Events';
import Admin from './Modules/Admin';
import User from './Modules/User';
import News from './Modules/News';
import Home from './Modules/Home';

//Single Pages
import Contact from './Pages/Contact';
import Apply from './Pages/Apply';
import Login from './Pages/Login';
import Pages from './Pages/Pages';

function App() {

  return (
    <AuthProvider>
      <div className="App">
        <Navbar/>
        <Routes>
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Layout />}>
              <Route exact path='/' element={<Home />}/>
              <Route exact path='/profiles/*' element={<Profiles />} />
              <Route exact path='/gallery/*' element={<Gallery />} />
              <Route exact path='/blog/*' element={<Articles/>} />
              <Route exact path='/events/*' element={<Events />} />
              <Route exact path='/contact' element={<Contact />} />
              <Route exact path='/apply/*' element={<Apply />} />
              <Route exact path='/news/*' element={<News />} />
              <Route exact path='/login' element={<Login />} />
              <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4, 5]} />}>
                <Route exact path='/user/*' element={<User />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[4, 5]} />}>
                <Route exact path='/admin/*' element={<Admin />} />
              </Route>
              <Route exact path='/*' element={<Pages />} />
            </Route>
          </Route>
        </Routes>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;