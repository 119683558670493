import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import ProfilesPageBanner from "./ProfilesPageBanner";
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import { useState } from "react";

//Editor
import EditorToolbar, { modules, formats } from "../../Components/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../css/TextEditor.css";

const UPLOAD_FILE_URL = '/upsingle';
const ADD_PROFILE_URL = '/profiles/add';

function AddProfile() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [tag, setTag] = useState('');
  const [bio, setBio] = useState('');
  const [file, setFile] = useState(null);
  let navigate = useNavigate();

  const onbio = (value) => {
    setBio(value);
} 
  
  const profileSubmit = async (e) => {
      e.preventDefault();
      const newProfile = {name, tag, position, bio, userId: authDecode.user.id};

      if(file){
          const formData = new FormData();
          const filename = Date.now() + file.name;
          
          formData.append("name", filename);
          formData.append("file", file);
          newProfile.pictures = filename;

          try{
              await axiosPrivate.post(UPLOAD_FILE_URL, formData);
          }catch(err){
              console.log(err);
          }
      }

      try {
          await axiosPrivate.post(ADD_PROFILE_URL, newProfile).then((response)=>{
              console.log(response);
              navigate('/profiles');
          });
      }catch (error) {
          console.log(error);  
      }
  };

  return (
    <>
      <ProfilesPageBanner PT='New Profile'/>
      <section className="pt-90 pb-120 gray-bg">
        <div className="container">
          <div className='main-form'>
            <form onSubmit={profileSubmit}>
                <div className="row">
                    <div className="col-lg-4 col-md-8">
                        <div className="teachers-left mt-50">
                            <div className="hero">
                            {file && (
                                <img src={URL.createObjectURL(file)} alt="" />
                            )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="fileInput">
                                    <br />
                                    { file ? <h5><i className="fa fa-recycle" /> Change Image</h5> : <h5><i className="fa fa-image" /> Add Image</h5>}
                                </label>
                                <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                            </div>
                            <div className="form-group section-title">
                                <h5>Name</h5>
                                <input type="text" value={name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setName(e.target.value)} />
                            </div>
                            <div className="form-group section-title">
                                <br /><h5>Group</h5>
                                <select name="tag" value={tag} onChange={(e) => setTag(e.target.value)}>
                                    {!tag ? <option defaultValue>Select Grouping</option> : <option>Select Grouping</option>}
                                    { tag ===10 ? <option value="10" defaultValue>Governors</option> : <option value="10">Governors</option>}
                                    { tag ===30 ? <option value="20" defaultValue>Management</option> : <option value="20">Management</option>}
                                    { tag ===30 ? <option value="30" defaultValue>Teachers</option> : <option value="30">Teachers</option>}
                                </select>
                            </div>
                            <div className="form-group section-title">
                                <br /><h5>Position</h5>
                                <input type="text" value={position} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setPosition(e.target.value)} />
                            </div>
                        </div> { /* <!-- teachers left --> */}
                    </div>
                    <div className="col-lg-8 mt-50">
                        <div className='form-group section-title'>
                          <h5>Bio:</h5>
                          <EditorToolbar toolbarId={'t1'} />
                          <ReactQuill theme="snow" rows="10" modules={modules('t1')} formats={formats} value={bio} onChange={onbio} />
                        </div>
                        <br />
                        <button className="main-btn" disabled={!file || !name || !position || !tag || !bio} type="submit">Submit</button>
                        &nbsp;&nbsp;&nbsp;
                        <Link className="main-btn" to='/'>Cancel</Link>
                    </div>
                </div>
            </form>
          </div>         
        </div>
      </section>
    </>

  );
}

export default AddProfile