/* eslint-disable jsx-a11y/anchor-has-content */
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate, useParams} from 'react-router-dom';
import EventsPageBanner from './EventsPageBanner';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

const VIEW_EVENTS_URL = '/events/view/';
const DELETE_EVENT_URL = '/events/delete/';
const UPLOAD_FILE_URL = '/upsingle';
const EDIT_EVENT_URL = '/events/edit/';

function ViewEvent() {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

    const [updateMode, setUpdateMode] = useState(false);
    const [calEvent, setCalEvent] = useState({}); 
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    let {id} = useParams();
    
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_EVENTS_URL + id).then((response)=>{
        if(isMounted){
            setCalEvent(response.data)
            setTitle(response.data.title);
            setLocation(response.data.location);
            setEventDate(response.data.date);
            setDescription(response.data.description);
          }
      }).catch((error) => {
        //Handle Errors Coming out of this
    });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
    }, []);
  
    const updateCalEvent = async(e) => {
        e.preventDefault();
        const date = eventDate;
        const updatedEvent = {title, date, location, description, UserId: authDecode.user.id, };
          
        if(file){
          const formData = new FormData();
          const filename = Date.now() + '--' + file.name;
              
          formData.append("name", filename);
          formData.append("file", file);
          updatedEvent.picture = filename;
        
          try{
            await axiosPrivate.post(UPLOAD_FILE_URL, formData);
          }catch(err){
            console.log(err);
          }
        }
  
        try {
          axiosPrivate.put(EDIT_EVENT_URL + calEvent.id, updatedEvent).then(()=>{
            alert('Calendar Event Edited');
            navigate('/events');
          }).catch(function(error){
              console.log(error.config);
          });   
        }catch (error) {
            console.log(error);  
        }
    }

    const deleteCalEvent = async(id) => {
      try {
        axiosPrivate.delete(DELETE_EVENT_URL + id).then(()=>{
          alert('Calendar Event Deleted');
          navigate('/events');
        });               
      } catch (error) {       
      }
    };

    return (
        <>
            <EventsPageBanner PT={calEvent.title} />
            <section id="event-singel" className="pt-90 pb-120 gray-bg">
                <div className="container">
                    <div className="events-area">
                        { updateMode ? 
                            <div className='main-form'>
                                <form data-toggle="validator">
                                    <div className="form-group section-title">
                                        <br/><h5>Event Name</h5>
                                        <input type='text' value={title} className="form-control p-2" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />         
                                        <div className="help-block with-errors"></div>
                                    </div> {/* Event Title */}
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="events-left">
                                                { file ? 
                                                    <img src={URL.createObjectURL(file)} alt="" />
                                                : 
                                                    <img src={'/images/' + calEvent.picture} alt=''/>
                                                }
                                            </div>{/* Event Image */}
                                            <div className='form-group'>
                                                <label htmlFor='fileInput'><br /><h5>Change Image: <i className="fa fa-recycle"></i></h5></label>
                                                <input type='file' id='fileInput' style={{display: 'none'}} onChange={(e)=>setFile(e.target.files[0])} />
                                            </div> {/* Change Image */}
                                        </div>{/* Main */}
                                        <div className="events-left col-lg-6">
                                            <div className="events-right pt-10">
                                                <div className="form-group section-title">
                                                    <br/><h5>Date</h5>
                                                    <input value={eventDate} type='date' className="p-2 form-control" autoFocus={true} onChange={(e)=>setEventDate(e.target.value)} />
                                                    <div className="help-block with-errors"></div>
                                                </div> {/* Event Date*/}               
                                                <div className="form-group section-title">
                                                    <br /><h5>Location</h5>
                                                    <input value={location} type='text' placeholder='' className="form-control p-2" autoFocus={true} onChange={(e)=>setLocation(e.target.value)} />
                                                    <div className="help-block with-errors"></div>
                                                </div>{/* Location */}
                                                <div className="form-group section-title">
                                                    <br /><h5>Description</h5>
                                                    <textarea value={description} type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
                                                    <div className="help-block with-errors"></div>
                                                </div>{/* Description */}
                                            </div> { /* <!-- events right --> */}
                                        </div>{/* Rightside */}
                                    </div>
                                    <br />
                                    <button className="main-btn" disabled={!title || !eventDate || !location || !description} type="submit" onClick={(e)=>updateCalEvent(e)}>Submit</button>
                                    &nbsp;&nbsp;&nbsp;  
                                    <button className="main-btn" onClick={(e)=>setUpdateMode(false)}>Cancel</button>
                                </form>
                            </div>
                        :                        
                            <div className='row'>
                                <div className="col-lg-8">
                                    <div className="events-left">
                                        <h3>{calEvent.title}</h3>
                                        <img src={'/images/' + calEvent.picture} alt=''/>
                                        <p dangerouslySetInnerHTML={{ __html: calEvent.description}} />
                                    </div> { /* <!-- events left --> */}
                                </div>
                                <div className="col-lg-4">
                                    <div className="events-right">
                                        <div className="events-coundwon bg_cover" data-overlay="8" style={{backgroundImage: 'url(/images/event/singel-event/coundown.jpg)'}}>
                                            <div data-countdown={new Date(calEvent.date).toDateString()}></div>
                                            <div className="events-coundwon-btn pt-30">
                                                <Link to="#book" className="main-btn">Confirm Attendance</Link>
                                            </div>
                                        </div>{ /*  <!-- events coundwon --> */}
                                        <div className="events-address mt-30">
                                            <ul>
                                                <li>
                                                    <div className="singel-address">
                                                        <div className="icon">
                                                            <i className="fa fa-calendar"></i>
                                                        </div>
                                                        <div className="cont">
                                                            <h6>Date</h6>
                                                            <span>{new Date(calEvent.date).toDateString()}</span>
                                                        </div>
                                                    </div> { /* <!-- singel address --> */}
                                                </li>
                                                <li>
                                                    <div className="singel-address">
                                                        <div className="icon">
                                                            <i className="fa fa-map"></i>
                                                        </div>
                                                        <div className="cont">
                                                            <h6>Address</h6>
                                                            <span>{calEvent.location}</span>
                                                        </div>
                                                    </div> { /* <!-- singel address --> */}
                                                </li>
                                                {(authDecode && (authDecode?.user?.id === calEvent.UserId || authDecode?.user?.role >=4))&& 
                                                    <>
                                                        <li>
                                                            <div className="singel-address">
                                                                <div className="icon">
                                                                <Link to='#edit'><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/></Link>
                                                                </div>
                                                                <div className="cont">
                                                                    <h6>Edit</h6>
                                                                </div>
                                                            </div> { /* <!-- singel address --> */}
                                                        </li>
                                                        <li>
                                                            <div className="singel-address">
                                                                <div className="icon">
                                                                <Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteCalEvent(calEvent.id)} /></Link>
                                                                </div>
                                                                <div className="cont">
                                                                    <h6>Delete</h6>
                                                                </div>
                                                            </div> { /* <!-- singel address --> */}
                                                        </li>                                           
                                                    </>
                                                }
                                            </ul>
                                            <div id="contact-map" className="mt-25"></div> { /* <!-- Map --> */}
                                        </div> { /* <!-- events address --> */}
                                    </div> { /* <!-- events right --> */}
                                </div>                            
                            </div>
                        }
                    </div> { /* <!-- events-area --> */}
                </div> { /* <!-- container --> */}
            </section>
        </>    
    )
}

export default ViewEvent