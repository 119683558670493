import React from 'react'

function Facilities() {
  return (
    <section id="video-feature" className="bg_cover pt-60 pb-110" style={{backgroundImage: 'url(images/347404929_772168164687419_958909718389143112_n.jpg)'}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-last order-lg-first">
            
          </div>
          <div className="col-lg-5 offset-lg-1 order-first order-lg-last">
            <div className="feature pt-50">
              <div className="feature-title">
                <h3>Our Facilities</h3>
              </div>
              <ul>
              <li>
                  <div className="singel-feature">
                    <div className="icon">
                      <img src="images/all-icon/f-3.png" alt="icon" />
                    </div>
                    <div className="cont">
                      <h4>Books &amp; Library</h4>
                      <p>Gravida nibh vel velit auctor aliquetn auci elit cons solliazcitudirem sem quibibendum sem nibhutis.</p>
                    </div>
                  </div> 
                </li>
                <li>
                  <div className="singel-feature">
                    <div className="icon">
                      <img src="images/all-icon/sports.png" alt="icon"/>
                    </div>
                    <div className="cont">
                      <h4>Sports</h4>
                      <p>Gravida nibh vel velit auctor aliquetn auci elit cons solliazcitudirem sem quibibendum sem nibhutis.</p>
                    </div>
                  </div> 
                </li>
                <li>
                  <div className="singel-feature">
                    <div className="icon">
                      <img src="images/all-icon/computer.png" alt="icon" />
                    </div>
                    <div className="cont">
                      <h4>Information Technology</h4>
                      <p>Gravida nibh vel velit auctor aliquetn auci elit cons solliazcitudirem sem quibibendum sem nibhutis.</p>
                    </div>
                  </div> 
                </li>
              </ul>
            </div>
          </div>
        </div> 
      </div> 
      <div className="feature-bg" /> 
    </section>
  )
}

export default Facilities