import { Link, useNavigate} from 'react-router-dom';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import useAuth from '../Hooks/useAuth';
import {useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode';

//Editor
import EditorToolbar, { modules, formats } from "../Components/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/TextEditor.css";

const VIEW_PAGE_URL = '/pages/view/';
const EDIT_PAGE_URL = '/pages/edit/';
const UPLOAD_FILE_URL = '/upsingle';

function Page({id}) {
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [page, setPage] = useState({});  
    const [title, setTitle] = useState('');
    const [pageIntro, setPageIntro] = useState('');
    const [pageText, setPageText] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);  

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    //let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PAGE_URL + id).then((response)=>{
            if(isMounted){
                setPage(response.data);
                setTitle(response.data.title);
                setPageIntro(response.data.pageIntro);
                setPageText(response.data.pageText);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const onPageIntro = (value) => {
        setPageIntro(value);
    }

    const onPageText = (value) => {
        setPageText(value);
    }
    const updatePage = async(e) => {
        e.preventDefault();
        const updatedPage = {title, pageIntro, pageText, id: page.id};
        const pageId = page.id;

        if(file){
            const formData = new FormData();
            const filename = Date.now() + file.name;          
            formData.append("name", filename);
            formData.append("file", file);
            updatedPage.picture = filename;
      
            try{
              await axiosPrivate.post(UPLOAD_FILE_URL, formData);
            }catch(err){
              console.log(err);
            }
        }
        
        try {
            axiosPrivate.put(EDIT_PAGE_URL + pageId, updatedPage).then(()=>{
                alert('Page Edited');
                setUpdateMode (false);
                navigate(`/about/${pageId}`);
            });   
        }catch (error) {
            console.log(error);  
        }
    }
 
    return (
        <>
            <section id="page-banner" className="pt-105 pb-80 bg_cover" data-overlay="8" style={{backgroundImage: 'url(images/page-banner-6.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-banner-cont">
                                <h2>{page.title}</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{page.title}</li>
                                    </ol>
                                </nav>
                            </div>  { /* <!-- page banner cont --> */}
                        </div>
                    </div> { /* <!-- row --> */}
                </div> { /* <!-- container --> */}
            </section>
            <section id="about-page" className="pt-70 pb-110 gray-bg">
                <div className="container">
                    { updateMode ? (<>
                        <form className="p-2">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="section-title mt-50">
                                        <h5>{page.tag}</h5>
                                    </div> { /*<!-- section title --> */}                       
                                    <div className="section-title form-group">
                                        <br /><h5>Title:</h5> <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
                                    </div>
                                    <div className="section-title form-group">
                                        <br /><h5>Page Intro:</h5>
                                        <EditorToolbar toolbarId={'t1'} />
                                        <ReactQuill theme="snow" rows="3" modules={modules('t1')} formats={formats} value={pageIntro} onChange={onPageIntro} />
                                    </div>
                                </div>
                                <div className="form-group col-lg-7">                            
                                    <div className="about-image mt-50">                           
                                        { file ? (
                                            <img className="img-fluid w-100" src={URL.createObjectURL(file)} alt="" />
                                        ): (
                                            page?.picture && <img src={'/images/' + page.picture} alt={page.tag} />
                                        )}
                                    </div>  { /*<!-- about imag --> */}
                                    <br /><label htmlFor="fileInput"><h5>Change Image: <i className="fa fa-recycle" /></h5></label>
                                    <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                </div>
                            </div>
                            <div className="section-title form-group">
                                <br /><h5>Page Details:</h5>
                                <EditorToolbar toolbarId={'t2'} />
                                <ReactQuill theme="snow" rows="10" modules={modules('t2')} formats={formats} value={pageText} onChange={onPageText} />
                            </div>
                            <br />
                            <button type="submit" className="main-btn" onClick={updatePage}>Update</button>
                            &nbsp;&nbsp;&nbsp;  
                            <Link className="main-btn" onClick={(e)=>setUpdateMode(false)} to={`/${id}`}>Cancel</Link> 
                        </form> 
                    </>) : (<>
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="section-title mt-50">
                                    <h5>{page.tag}</h5>
                                    {authDecode && authDecode.user.role >= 4 ? (
                                        <span className='about-cont'>
                                            <Link to='#'><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/> </Link>
                                            &nbsp;Edit
                                        </span>
                                    ):(<></>)}
                                    <h2>{title}</h2>
                                </div> { /*<!-- section title --> */}
                                <p className="about-cont pt-20" dangerouslySetInnerHTML={{ __html: pageIntro}} style={{whiteSpace: "pre-line"}} />
                            </div>{ /* <!-- about cont --> */}
                            <div className="col-lg-7">
                                <div className="about-image mt-50">                           
                                    { file ? (
                                        <img className="img-fluid w-100" src={URL.createObjectURL(file)} alt="" />
                                    ): (
                                        page?.picture && <img src={'/images/' + page.picture} alt={page.tag} />
                                    )}
                                </div>  { /*<!-- about imag --> */}
                            </div>
                        </div> { /*<!-- row --> */}
                        <div className='about-items pt-20'>
                            <p className="justify-content-center" dangerouslySetInnerHTML={{ __html: page.pageText}} style={{whiteSpace: "pre-line"}} />
                        </div>{ /*<!-- about items --> */}              
                    </>)}
                </div> { /*<!-- container --> */}
            </section>        
        </>
    )
}

export default Page